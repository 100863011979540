/**
 * タグの一覧表示用テンプレート(XDのtaglist)
 */
import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';
import Tag from '@/atoms/Tag';
import Layout from '@/templates/Layout';
import ArchiveHeading from '@/atoms/ArchiveHeading';
import Sidebar from '@/organisms/Sidebar';
import Seo from '@/components/Seo';
import { Content } from '@/templates/top';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-width: 960px;
`;

const Item = styled.li`
  margin: 0 16px 15px 0;

  @media (max-width: 767px) {
    margin: 0 12px 11px 0;
  }
`;

const TagListPage: React.FC<PageProps<GatsbyTypes.TagListPageQuery>> = ({
  data,
  location,
}) => {
  const tags = data.allSiteTag.nodes as GatsbyTypes.SiteTag[];
  const { pathname, href, origin } = location;

  return (
    <>
      <Seo url={href || '/tag/'} origin={origin} uniqueTitle="タグ一覧" />
      <Layout>
        <ArchiveHeading>タグ一覧</ArchiveHeading>
        <Content>
          <List>
            {tags.map((tag) => {
              const slug = tag.slug || '';
              const title = tag.title || '';

              return (
                <Item key={tag.title}>
                  <Tag href={`/tag/${slug}`} label={title} />
                </Item>
              );
            })}
          </List>
          <Sidebar pathname={pathname} />
        </Content>
      </Layout>
    </>
  );
};

export const query = graphql`
  query TagListPage {
    allSiteTag {
      nodes {
        slug
        title
      }
    }
  }
`;

export default TagListPage;
